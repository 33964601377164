import GATSBY_COMPILED_MDX from "/opt/build/repo/content/kariera/operator-walca-brygada-bitumiczna.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {MDXProvider} from "@mdx-js/react";
import Header from '../components/header';
import Seo from '../components/seo';
import Container from '../components/container';
import AnimationWrapper from '../components/animationWrapper';
import InnerSection from '../components/innerSection';
import ContactTile from '../components/contactTile';
import ApplicationForm from '../components/applicationForm';
import Layout from '../components/layout';
import "./listingPage.css";
const ListingPage = ({data, location, children}) => {
  const file = data.listing.internal.contentFilePath.split('/').pop();
  const listingSlug = file.slice(0, file.lastIndexOf('.'));
  const MyH2 = props => React.createElement("h2", Object.assign({
    className: "section-heading"
  }, props));
  const components = {
    h2: MyH2
  };
  return React.createElement(Layout, {
    location: location,
    path: listingSlug
  }, React.createElement(Seo, {
    title: `${data.listing.frontmatter.title} - Kariera - Transpol Lider`
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    pageName: data.listing.frontmatter.title,
    data: data.header,
    location: listingSlug
  })), React.createElement("section", {
    id: "listing-description",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement("div", {
    className: "section-description listing-description"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))))), React.createElement("section", {
    id: "listing-description",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement("div", {
    className: "section-description section-description-small listing-description"
  }, React.createElement("p", null, "Administratorem Twoich danych osobowych jest: Transpol Lider spółka z ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w Inowrocławiu, Łojewo 70, 88-101 Inowrocław, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Bydgoszczy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000597633, nr NIP 556-276-22-49 Klauzula informacyjna dostępna jest na stronie internetowej spółki."))))), React.createElement("section", {
    id: "listing-application",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(InnerSection, {
    className: "contact-data application-form-wrapper"
  }, React.createElement("div", {
    className: "application-form-container"
  }, React.createElement(AnimationWrapper, null, React.createElement("h2", {
    className: "section-heading"
  }, "Formularz aplikacyjny")), React.createElement(ApplicationForm, {
    position: data.listing.frontmatter.title
  })), React.createElement(ContactTile, {
    contactData: data.contactData.frontmatter
  })))));
};
export const query = graphql`
  query(
      $slug: String,
      $headerPath: String,
    ) {
      listing: mdx(internal: { contentFilePath: { regex: $slug } }) {
        frontmatter {
          title
        }
        internal {
          contentFilePath
        }
      }
      contactData: mdx(internal: {contentFilePath: {regex: "/transpol-lider/"}}) {
      frontmatter {
        name
        slug
        address
        email
        phone
        phone_alt
        phone_postfix
      }
    }
      header: file(relativePath: {eq: $headerPath}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 95
            transformOptions: {fit: COVER}
            webpOptions: {quality: 95}
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
`;
ListingPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ListingPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
